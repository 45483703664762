import React from 'react'
import './header.scss'
import loadable from '@loadable/component'
import Marked from 'react-markdown'
const Fade = loadable(() => import('../transition/Fade'))

const Header = ({position}) => {
    return (
      <div className="single-post-container d-lg-flex" id="common-px">
        <div className="title-container">
          <Fade timeout={1000}>
            <p className="sub-title">{position.location}</p>
          </Fade>
          <Fade timeout={500}>
            <h2>{position.bannerTitle}</h2>
          </Fade>
          <Fade timeout={1000}>
            <p
              className="title-dec"
            >
              <Marked parserOptions={{ commonmark: true}} source={position.bannerDescription} />
            </p>
          </Fade>
        </div>
        <div className="img-container d-none d-lg-block align-self-lg-end">
          <img
            src={position.bannerImage}
            alt={position.bannerTitle}
            className="post-img"
          />
        </div>
      </div>
    )
}

export default Header